<template>
  <v-card>
    <v-stepper v-model="steps">
      <v-stepper-header>
        <v-stepper-step :complete="steps > 1" step="1">
          {{ $t("msh.devices.provision.findDevice") }}
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="steps > 2" step="2">
          {{ $t("msh.devices.provision.findAppartment") }}
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="3"> Provision </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <!-- Find Device with product name -->
        <v-stepper-content step="1">
          <v-card class="mb-12" color="grey lighten-4" height="200px">
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="5">
                    <v-text-field
                      v-model="productName"
                      prefix="MH-A1-"
                      label="Product name"
                    ></v-text-field>
                    <div v-if="device">
                      Device found with serial number '{{
                        device.serialNumber
                      }}'
                    </div>
                    <v-btn @click="searchSerialNumber()">Search</v-btn>
                  </v-col>
                  <v-col>
                    <!-- Center this ... -->
                    <span class="center">OR</span>
                  </v-col>
                  <v-col cols="5">
                    <v-text-field
                      v-model="serialNumberProvided"
                      label="Serial Number"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>

          <v-btn color="primary" @click="steps = 2" :disabled="step1Valid">
            Continue
          </v-btn>

          <!-- <v-btn text> Cancel </v-btn> -->
        </v-stepper-content>

        <!-- Find Appartment with dropdowns -->
        <v-stepper-content step="2">
          <v-card
            class="mb-12"
            color="grey lighten-4"
            height="200px"
            v-if="structures && structures.length > 0"
          >
            <v-select
              v-model="selectedAppartment"
              :items="structures"
              item-text="label"
              item-value="structureId"
              label="Appartment"
            ></v-select>
          </v-card>

          <v-btn color="primary" @click="steps = 3" :disabled="step2Valid">
            Continue
          </v-btn>

          <v-btn text @click="back(2)"> Back </v-btn>
        </v-stepper-content>

        <!-- Provide name and confirm provision with Bluetooth serial key -->
        <v-stepper-content step="3">
          <v-card class="mb-12" color="grey lighten-4" height="200px">
            <v-text-field
              v-model="deviceLabel"
              label="Device label"
            ></v-text-field>
            <div v-if="device">
              Hint search for a device named MSH-{{
                device.iotDeviceId.substring(1)
              }}
            </div>
            <v-btn @click="connectToDevice()"
              >Connect to device to 'add serialNumber' here</v-btn
            >
            <!-- Connect -->
            <div>
              <!--  -->
            </div>
          </v-card>

          <v-btn
            color="primary"
            @click="provision()"
            :disabled="provisionDisabled"
            :loading="loadingProvision"
          >
            Provision
          </v-btn>

          <v-btn text @click="back(3)"> Back </v-btn>

          <v-alert text type="error" v-if="provisionErrored">
            An error occurred when unprovisionning this device.
            {{ errorMessage }}
          </v-alert>
          <v-alert text type="info" v-if="provisionSucceed">
            Device successfully provisionned.
          </v-alert>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-card>
</template>
<script>
import { mapState } from "vuex";
import {
  DEVICE_GET_BY_PRODUCT_NAME_REQUEST_PROMISE,
  FLOOR_GET_DEVICES_REQUEST,
  DEVICE_PROVISION_DEVICE_REQUEST,
} from "@/store/constants";
import store from "@/store";
import publicKeyTrait from "@/grpc/msh/trait/bluetooth/v1/public_key_pb";
import {
  DEVICE_PROVISION_DEVICE_SUCCEEDED,
  DEVICE_PROVISION_DEVICE_ERROR,
} from "@/events";
import { bus } from "@/main.js";

export default {
  name: "provisionSteps",
  data() {
    return {
      steps: 1,
      productName: "",
      deviceLabel: "",
      serialNumberProvided: "",
      selectedAppartment: "",
      device: null,
      searchError: null,
      publicKey: null,
      loadingProvision: false,
      provisionErrored: false,
      provisionSucceed: false,
    };
  },
  methods: {
    /**
     * Search a device by it's product name
     */
    searchSerialNumber() {
      if (this.productName) {
        store
          .dispatch(
            DEVICE_GET_BY_PRODUCT_NAME_REQUEST_PROMISE,
            "MH-A1-" + this.productName
          )
          .then(
            (deviceResponse) => {
              // resolved
              this.device = deviceResponse;
            },
            (err) => {
              // reject part
              this.searchError = err;
            }
          );
      }
    },
    /**
     * Connect to device
     *  - Request device
     *  - Connect
     *  - Get public Key
     */
    connectToDevice() {
      window.navigator.bluetooth
        .requestDevice({
          acceptAllDevices: true,
          optionalServices: ["935bb268-d069-11ec-9d64-0242ac120002"],
        })
        .then((bluetoothDevice) => {
          let connectPromise = new Promise((resolve) => {
            if (bluetoothDevice.gatt.connected) {
              bluetoothDevice.gatt
                .getPrimaryService("935bb268-d069-11ec-9d64-0242ac120002")
                .then((service) => {
                  resolve(service);
                });
            } else {
              bluetoothDevice.gatt
                .connect()
                .then((server) => {
                  return server.getPrimaryService(
                    "935bb268-d069-11ec-9d64-0242ac120002"
                  );
                })
                .then((service) => {
                  resolve(service);
                });
            }
          });
          connectPromise
            .then((service) => {
              let publicKeyPromise = new Promise((resolve) => {
                service
                  .getCharacteristic("b3e36822-d09c-11ec-9d64-0242ac120002")
                  .then((characteristic) => {
                    return characteristic.readValue();
                  })
                  .then((data) => {
                    var message =
                      publicKeyTrait.PublicKeyTrait.ReadCommand.deserializeBinary(
                        data.buffer
                      );
                    resolve(message.getPublicKey());
                  });
              });
              publicKeyPromise
                .then((key) => {
                  this.publicKey = key;
                })
                .catch((error) => {});
            })
            .catch((error) => {});
        });
    },
    /**
     * Provision the device with information from all the steps
     */
    provision() {
      this.loadingProvision = true;
      let serialNumberToProvision;
      if (this.serialNumberProvided) {
        serialNumberToProvision = this.serialNumberProvided;
      } else {
        if (this.device) {
          serialNumberToProvision = this.device.serialNumber;
        }
      }

      const provRequest = {
        serialNumber: serialNumberToProvision,
        publicKey: this.publicKey,
        structureId: this.selectedAppartment,
        deviceLabel: this.deviceLabel,
      };

      // Add loading here
      store.dispatch(DEVICE_PROVISION_DEVICE_REQUEST, provRequest);
    },
    /**
     * Move back from current step to previous one
     */
    back(currentStep) {
      if (currentStep == 3) {
        // From device label + bluetooth to structure
        this.deviceLabel = "";
        this.publicKey = "";
        this.steps = 2;
      } else if (currentStep == 2) {
        this.selectedAppartment = "";
        this.steps = 1;
      }
    },
  },
  computed: {
    /**
     * Structure list for appartment selection for the device
     */
    ...mapState({
      structures: (state) => state.floors.floor_appartments,
    }),
    /**
     * Check that a serial number was searched or provided
     */
    step1Valid() {
      if (this.device != null || this.serialNumberProvided) {
        return false;
      }
      return true;
    },
    /**
     * Check that a serial number was searched or provided
     */
    step2Valid() {
      if (this.selectedAppartment) {
        return false;
      }
      return true;
    },
    /**
     * Check that the information is available to provision the device
     */
    provisionDisabled() {
      if (this.publicKey == null && this.deviceLabel.length == 0) {
        return true;
      }
      return false;
    },
  },
  mounted() {
    // Bus on unprovision succeed
    bus.$on(DEVICE_PROVISION_DEVICE_SUCCEEDED, (provResponse) => {
      this.loadingProvision = false;
      this.provisionSucceed = true;
      setTimeout(() => {
        this.steps = 1;
        this.productName = "";
        this.deviceLabel = "";
        this.serialNumberProvided = "";
        this.selectedAppartment = "";
        this.device = null;
        this.searchError = null;
        this.publicKey = null;
        this.provisionSucceed = false;
        this.provisionErrored = false;
      }, 2000);
    });
    // Bus on unprovision errored
    bus.$on(DEVICE_PROVISION_DEVICE_ERROR, (provResponse) => {
      this.loadingProvision = false;
      this.provisionErrored = true;
      this.errorMessage = provResponse.error;
    });
  },
  // Making sure we have the list of appartments
  async beforeRouteEnter(to, from, next) {
    store.dispatch(FLOOR_GET_DEVICES_REQUEST, to.params.buildingId);
    next();
  },
};
</script>
